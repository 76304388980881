import React from "react";
import {
  SEO,
  PageLayout,
  InternalLink,
  OutboundLink,
} from "@bluefin/components";
import { Grid, Card, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <div>
              <div>
                <div className={"yt-container"}>
                  <iframe
                    width={"100%"}
                    height={"700"}
                    src={
                      "https://player.vimeo.com/video/855517673?autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1"
                    }
                    frameborder={"0"}
                    allow={"autoplay; fullscreen; picture-in-picture"}
                    allowfullscreen={false}
                  />
                </div>
              </div>
            </div>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Grid
            columns={1}
            stackable={true}
            doubling={true}
            className={"home-content"}
            textAlign={"center"}
          >
            <Grid.Column>
              <Card className={"custom-card-container"}>
                <Card.Content className={"custom-card-content custom-card-1"}>
                  <Card.Description className={"custom-card-description"}>
                    <div className={"custom-card-overlay"}>
                      <h1>WE ARE QUESOS</h1>
                      <p>
                        The place to be when you're looking for a
                        family-friendly spot that serves up the best tequila and
                        handcrafted margaritas, as well as tacos and honest
                        Mexican food. We bring our ancestors' old and authentic
                        recipes into the modern age with a modern feel.
                      </p>
                      <Button
                        primary={true}
                        size={"small"}
                        to={"/about/"}
                        as={InternalLink}
                      >
                        About Us
                      </Button>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"home-content"}
            textAlign={"center"}
          >
            <Grid.Column>
              <Card className={"custom-card-container"}>
                <Card.Content className={"custom-card-content custom-card-3"}>
                  <Card.Description className={"custom-card-description"}>
                    <div className={"custom-card-overlay"}>
                      <h1>WEEKLY SPECIALS</h1>
                      <div>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/SPECIALS_NOBLESVILLE_2024_compressed.pdf"
                          }
                          as={OutboundLink}
                        >
                          Noblesville
                        </Button>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/SPECIALS_PLAINFIELD_2024_compressed.pdf"
                          }
                          as={OutboundLink}
                        >
                          Plainfield
                        </Button>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/banner_iowa2024-min-compressed.pdf"
                          }
                          as={OutboundLink}
                        >
                          Burlington
                        </Button>
                      </div>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card className={"custom-card-container"}>
                <Card.Content className={"custom-card-content custom-card-4"}>
                  <Card.Description className={"custom-card-description"}>
                    <div className={"custom-card-overlay"}>
                      <h1>COCINA + CANTINA</h1>
                      <p>
                        If you're feeling hungry, visit our Cocina + Cantina
                        section. We have a wide selection of food and drink to
                        choose from.
                      </p>
                      <div>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/MENU_NOBLESVILLE_2024.pdf"
                          }
                          as={OutboundLink}
                        >
                          Noblesville
                        </Button>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/COCINA_CANTINA_PLAINFIELD.pdf"
                          }
                          as={OutboundLink}
                        >
                          Plainfield
                        </Button>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/COCINA_CANTINA_BURLIGTON.pdf"
                          }
                          as={OutboundLink}
                        >
                          Burlington
                        </Button>
                      </div>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card className={"custom-card-container"}>
                <Card.Content className={"custom-card-content custom-card-5"}>
                  <Card.Description className={"custom-card-description"}>
                    <div className={"custom-card-overlay"}>
                      <h1>CATERING & TACO TRUCKS</h1>
                      <p>
                        Our catering options are perfect for corporate events,
                        graduations, and more. And our Taco Trucks will add an
                        extra festive touch. Check out our location-specific
                        catering details!
                      </p>
                      <div>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/NOBLESVILLE__CATERING_2024.pdf"
                          }
                          as={OutboundLink}
                        >
                          Noblesville
                        </Button>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/BURLINGTON_CATERING_2024_6ity8Y0.pdf"
                          }
                          as={OutboundLink}
                        >
                          Burlington
                        </Button>
                        <Button
                          primary={true}
                          size={"small"}
                          to={
                            "https://fisherman.gumlet.io/public/quesosnoblesville/PLAINFIELD_CATERING_2024_iMg0rGV.pdf"
                          }
                          as={OutboundLink}
                        >
                          Plainfield
                        </Button>
                      </div>
                      <div className={"card-additional-info"}>
                        <span>
                          Our Taco Trucks are available in Noblesville and
                          Plainfield. Use&nbsp;
                        </span>
                        <a
                          href={"https://forms.gle/VozFsuh3ogcikFTU6"}
                          target={"_blank"}
                          className={"card-link"}
                        >
                          this link
                        </a>
                        <span>&nbsp;to request an event!</span>
                      </div>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
